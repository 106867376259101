.nav-container {
    padding: 24px 18px;
    background: #FFFFFF;
    height: 100vh;
    overflow: auto;
    position: fixed;
    width: 224px;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logo-wrapper {
    margin: 0 0 49px;
}

.logo-wrapper img {
    max-width: 100%;
}

.nav-item {
    display: flex !important;
    align-items: center !important;
    font-family: "Rubik", sans-serif !important;
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
    color: #3C3E49 !important;
    width: 100%;
    letter-spacing: 0.2px;
    transition: all 0.3s ease;
    border-radius: 5px;
    padding: 5px 4px;
}

.nav-accordion-title {
    border-radius: 8px !important;
    padding: 5px 4px !important;
}

.nav-item:hover {
    transition: all 0.3s ease;
    background-color: #F5F5F5;
    color: #3C3E49 !important;
}

.nav-item label {
    cursor: pointer;
}

.nav-item img:not(.arrow-img) {
    height: 32px;
    width: 32px;
    display: inline-block;
    margin: 0 3px 0 0;
    object-fit: contain;
    object-position: center;
}

.get-started-icon {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 14px;
    rotate: 0deg;
}

.nav-item-active {
    background-color: #F3EBFF;
    color: #6200EA !important;
}

.nav-item-active:hover {
    background-color: #F3EBFF;
    color: #6200EA !important;
}

.nav-accordion-title .MuiAccordionSummary-content {
    align-items: center;
    padding: 0;
}

.Mui-expanded .nav-accordion-title {
    color: #6200EA !important;
}

.nav-accordion .accordion-nav-list {
    padding-left: 40px !important;
    width: 100%;
}

.nav-accordion .MuiCollapse-wrapper {
    padding-top: 8px !important;
}

.nav-accordion .accordion-nav-list ul {
    width: 100%;
}

.nav-accordion .accordion-nav-list li {
    padding: 6px 4px 6px 8px;
    margin: 0 0 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #3C3E49;
    border-radius: 4px;
    cursor: pointer;
}

.nav-accordion .accordion-nav-list li.active-item {
    background-color: #F3EBFF;
    color: #6200EA !important;
}

.nav-accordion .accordion-nav-list li.not-active-item:hover {
    transition: all 0.3s ease;
    background-color: #F5F5F5;
    color: #3C3E49 !important;
}

.help-link-wrap {
    margin-top: 10px;
}

.help-link-wrap .help-link {
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #262626;
    cursor: pointer;
}

.help-link-wrap .help-link .help-img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    object-position: center;
    margin-right: 8px;
}

.help-link-wrap .help-link .tooltip-info-wrapper {
    top: 1px;
}

.watch-demo-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 6px;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #262626;
    transition: all 0.3s ease;
    margin-top: 10px;
}

.watch-demo-link:hover {
    background: #F3EBFF;
}

.watch-demo-link:hover .right-arrow {
    display: block;
}


.watch-demo-link .left-block {
    display: flex;
    align-items: center;
    gap: 14px;
}

.watch-demo-link .play-icon {
    width: 20px;
}

.watch-demo-link .right-arrow {
    width: 20px;
    display: none;
    transition: all 0.3s ease;
}

.instruction-video-modal .modal-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 12px;
}

.instruction-video-modal .modal-heading .close-btn {
    position: static;
    border: 1px solid #B388FF;
    border-radius: 7px;
    box-shadow: 0px 12px 30px 0px rgba(101, 31, 255, 0.1);
    width: 32px;
    height: 32px;
    padding: 8px;
}

.instruction-video-modal .modal-heading .close-btn:hover {
    background: #F3EBFF !important;
}

.instruction-video-modal .modal-heading h4 {
    margin-bottom: 0 !important;
    font-size: 20px !important;
    color: #000;
}

.instruction-video-modal.custom-modal .modal-body {
    width: 90%;
    padding: 16px;
    max-width: max-content;
}

.instruction-video-modal .video-wrap {
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.instruction-video-modal .video-wrap video {
    max-height: 80vh;
    max-width: 100%;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    min-width: 800px;
}