.top-nav-container {
    align-items: center;
    position: relative;
    z-index: 2;
}

.bredcrumb-text {
    color: #595959 !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: -0.6px !important;
}

.notification-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.notification-container .MuiAvatar-root {
    border-radius: 16px !important;
}

.text-color {
    color: #6200EA !important
}

.navigation-menu {
    display: flex;
    width: 186px;
    max-width: 220px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--base-blue-grey-2, #ECEDF0);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.side-arrow-img {
    width: 18px;
    height: 18px;
    padding: 4.333px 0px;
}

.display-flex {
    display: flex;
    align-items: center;
}

.notification-wrap i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    position: relative;
}

.notification-wrap i img {
    max-width: 100%;
    margin: 0 auto;
}

.notification-wrap i .dot {
    height: 10px;
    width: 10px;
    border: 1px solid #fff;
    background-color: #00E676;
    border-radius: 10px;
    position: absolute;
    bottom: 5px;
    right: 7px;
}

.notification-wrap .notification-badge {
    border: 1.69px solid #FFF;
    width: 9.38px;
    height: 9.38px;
    border-radius: 100%;
    background: #00E676;
    right: 9px;
    position: absolute;
    bottom: 9px;
}

.notification-popover .MuiPopover-paper {
    max-width: 316px;
    box-shadow: 0px 12px 30px 0px rgba(101, 31, 255, 0.1);
    border-radius: 8px;
    max-height: calc(100vh - 212px);
}

.notification-list-wrap .notification-sublist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 12px;
    font-family: 'Rubik', sans-serif;
    background: #FFFFFF;
}

.notification-list-wrap .notification-sublist .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #595959;
}

.notification-list-wrap .notification-sublist .underline-link {
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.01em !important;
    text-transform: unset;
    padding: 0;
    background: transparent;
}

.notification-list-wrap .notification-list {
    padding: 12px;
    border-bottom: 1px solid #DFE0E6;
}

.notification-list-wrap .notification-list .MuiTouchRipple-root {
    display: none;
}

.notification-list-wrap .notification-list:last-child {
    border-bottom: 0;
}

.notification-list-wrap .notification-list:hover,
.notification-list-wrap .notification-list.active {
    background: #FBF8FF;
}


.notification-list-wrap .list-inner {
    display: flex;
    width: 100%;
}

.notification-list-wrap .list-icon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    max-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #ECEDF0;
}

.notification-list-wrap .list-icon.red-bg {
    background: #FEE5E2;
}

.notification-list-wrap .list-icon.green-bg {
    background: #E2FCEA;
}

.notification-list-wrap .list-icon.purple-bg {
    background: #F3EBFF;
}

.notification-list-wrap .list-icon.yellow-bg {
    background: #FFF9E1;
}


.notification-list-wrap .list-content {
    flex: 1;
}

.notification-list-wrap .list-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
}

.notification-list-wrap .list-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #3C3E49;
    flex: 1;
}

.notification-list-wrap .list-time {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #8C8C8C;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.notification-list-wrap .is-read:after {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #6200EA;
    margin-left: 4px;
    display: none;
}

.notification-list-wrap .notification-list.active .is-read:after {
    display: block;
}

.notification-list-wrap .list-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #3C3E49;
    padding-right: 61px;
}

.notification-list-wrap .list-desc .purple-text {
    color: #6200EA;
    text-decoration: none;
}

.notification-list-wrap .collapse-list .list-heading {
    margin-bottom: 0;
}

.notification-list-wrap .collapse-list .list-expand {
    margin-left: 4px;
}

.notification-list-wrap .collapse-list .list-expand .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    background: #ECEDF0;
    border-radius: 100%;
    color: #000000;
}

.notification-list-wrap .collapse-list .MuiCollapse-root {
    margin-top: 7px;
}

.notification-list-wrap .weekly-list {
    margin-top: 16px;
}

.notification-list-wrap .weekly-list li {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #3C3E49;
}

.notification-list-wrap .weekly-list .title {
    min-width: 168px;
}

.notification-list-wrap .weekly-list .value {
    font-weight: 500;
    margin-left: 8px;
}

.notification-list-wrap .empty-notification {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
    padding: 50px 35px;
    min-height: calc(100vh - 264px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notification-list-wrap .empty-notification .empty-icon {
    width: 80px;
    height: 80px;
    margin: 0 auto 16px;
}

.notification-list-wrap .empty-notification .empty-tile {
    font-weight: 500;
    margin-bottom: 4px;
}