.attachment-block-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.attachment-block-wrap .attachment-label{
    font-family: 'Rubik', sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    color: #3C3E49;
    padding-right: 10px;

}
.attachment-block-wrap .add-attachment-link{
    font-family: 'Rubik', sans-serif !important;    
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #6200EA;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.attachment-block-wrap .add-attachment-link img{
    height: 14px;
    width: 14px;
    object-fit: contain;
    margin-right: 5px;
}
.attached-items-wrap{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px -4px;
}
.attached-items-wrap .attached-item{
    padding: 0 4px;
    margin-bottom: 4px;    
}
.attached-items-wrap .attached-item .item-inner-wrap{
    border-radius: 8px;
    border: 1px solid #DFE0E6;
    height: 72px;
    width: 114px;
    position: relative;    
}
.attached-items-wrap .attached-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 8px;
}
.attached-items-wrap .attached-item .close-btn{
    position: absolute;
    padding: 4px;
    height: 16px;
    width: 16px;
    background: #fff !important;
    top: 6px;
    right: 6px;
    box-shadow: 0px 1.3333333730697632px 2.6666667461395264px 0px #00000026;
}
/* custom-chatbox  */

.custom-chatbox{
    position: relative;
    flex: 1;
    padding-top: 10px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}
.custom-chat-outerwrap{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 87px);
    position: relative;
}
.custom-chat-outerwrap:before{
    content: '';
    position: absolute;
    top: -68px;
    left: 0;
    right: 0;
    height: 88px;
    width: 100%;
    background: linear-gradient(180deg, #F8F9FA 81.82%, rgba(248, 249, 250, 0.694524) 87.02%, rgba(248, 249, 250, 0) 98.86%);
    pointer-events: none;
    z-index: 1;
}
.custom-chatbox .chatbox-inner-wrap{
    max-width: 760px;
    margin: 0 auto;        
    overflow-y: auto;    
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    flex: 1;

}
.custom-chatbox .chatbox-inner-wrap::-webkit-scrollbar,
.custom-chatbox::-webkit-scrollbar {
    width: 0;
  }
  
.custom-chatbox .chatbox-inner-wrap::-webkit-scrollbar-thumb,
.custom-chatbox::-webkit-scrollbar-thumb {
background-color: transparent;
}
.chat-send-box{
    padding: 16px 0;
    border-top: 1px solid #DFE0E6;
    position: relative;
}
.chat-send-box .chat-sendbox-wrap{
    max-width: 760px;
    margin: 0 auto;
}
.chat-send-box .send-input-wrapper{    
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.chat-send-box .send-input-wrapper .MuiAvatar-root{
    height: 42px;
    width: 42px;
    background-color: #7C4DFF;
    margin-right: 15px;
    border-radius: 32px;
    color: #ffffff;
}
.chat-send-box .send-input-wrapper .send-input-block{    
    display: flex;
    align-items: flex-end;
    border: 1px solid #C0C3CE;
    border-radius: 12px !important;
    cursor: text;
    padding: 12px 12px 12px 24px;
    width: 100%;
}
.chat-send-box .send-input-wrapper .sender-block{
    padding-top: 12px;
}
.chat-send-box .send-input-wrapper .send-input-block .left-block{
    flex: 1;
}
.chat-send-box .send-input-wrapper .send-input-block .send-message-input{
    width: 100%;
}
.chat-send-box .send-input-wrapper .send-input-block .MuiInputBase-root{
    padding: 0  !important;
}
.MuiInput-underline:after,
.MuiInput-underline:before{
    display: none !important;
}
.chat-send-box .send-input-wrapper .send-input-block .MuiInputBase-input{
    padding: 10px 5px 13px 0 !important;
    background-color: transparent;

}
.chat-send-box .send-input-wrapper .send-input-block .MuiInputBase-input::-webkit-scrollbar {
    width: 0;
}
.chat-send-box .send-input-wrapper .send-btn-wrap{
    padding-left: 8px;
}
.chat-send-box .send-input-wrapper .send-btn-wrap .send-btn{
    min-width: auto;
    height: 41px !important;
    width: 41px;
}
.chat-send-box .send-input-wrapper .send-btn-wrap .send-btn.Mui-disabled{
    opacity: 0.3;
}
.chat-send-box .send-input-wrapper .send-btn-wrap .share-doc-btn{
    margin-right: 5px !important;
    padding: 8px !important;
}
.chat-send-box .send-input-wrapper .send-btn-wrap .share-doc-btn img{
    height: 20px;
    width: 20px;
    object-fit: contain;
    object-position: center;
}
.chat-send-box .send-input-wrapper .attached-items-wrap {
    margin-bottom: -4px;
}
.chat-send-box .send-input-wrapper .attached-items-wrap .attached-item .item-inner-wrap{
    width: 72px;
    cursor: pointer;

}
.chat-send-box .send-input-wrapper .attached-items-wrap .attached-item .close-btn{
    transition: all 0.5s;
    opacity: 0;
    padding: 0;
    height: 20px;
    width: 20px;
    top: -6px;
    right: -6px;
}
.chat-send-box .send-input-wrapper .attached-items-wrap .attached-item .item-inner-wrap:hover .close-btn{
    opacity: 1;
}
.ticket-detail-description{
    padding: 32px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ECEDF0;
    margin: 0 0 24px;
}
.ticket-detail-description .ticket-title-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 23px;
    border-bottom: 1px solid #DFE0E6;
}
.ticket-detail-description .ticket-title-block .status-badge{
    font-size: 14px;
}
.ticket-detail-description .ticket-title-block .left-title-block h3 {    
    font-family: 'Rubik', sans-serif !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    margin: 0 0 8px;
}
.ticket-detail-description .ticket-title-block .right-title-block .border-btn{
    padding: 10px;
    margin-left: 8px;
    min-width: auto;
}
.ticket-detail-description .ticket-title-block .right-title-block .border-btn img{
    height: 12px;
    width: 12px;    
}
.ticket-detail-description .ticket-title-block .right-title-block .light-btn{
    padding: 6px 16px;
}
.ticket-detail-description .ticket-title-block .right-title-block .light-btn img{
    width: 16px;
    height: 16px;
}
.ticket-detail-description .ticket-title-block .ticket-status-block {
    display: flex;
    align-items: center;    
}

.ticket-detail-description .ticket-title-block .ticket-status-block .timeline-text{    
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #83889E;
    margin-left: 12px;
}
.ticket-detail-description .ticket-description-block {
    padding-top: 16px;
}
.ticket-detail-description .ticket-description-block p{
    font-size: 16px;
    color:#595959;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 400;
    margin: 0 0 8px;
}
.ticket-detail-description .ticket-description-block .description-title{    
    font-weight: 500;        
    color: #3C3E49;
}
.ticket-detail-description .ticket-description-block .ticket-attachment-block{
    margin-top: 23px;
}
.ticket-detail-description .ticket-description-block .ticket-attachment-block .attachment-title-block{    
    font-size: 14px;
}
.message-block {
    margin: 0 0 12px;
}

.message-block .message-inner-wrap{
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 700px;
    padding: 8px;
    border-radius: 0 16px 16px 16px;
}
.message-block.builder-message .message-inner-wrap{
    padding: 16px;
    background-color: #fff;
}
.message-block .profile-picture-block .MuiAvatar-root {
    height: 32px;
    width: 32px;
    background-color: #7C4DFF;
    font-size: 14px;
}
.message-block.builder-message .profile-picture-block .MuiAvatar-root{
    background-color: #536DFE;
}
.message-block .message-content-block{
    padding-left: 12px;
    width: 100%;
}
.message-block .message-content-block p{
    letter-spacing: 0;
    color: #3C3E49;
}
.message-block .message-content-block .message-detail{
    display: flex;
    align-items: center;
    margin: 0 0 4px;
}

.message-block .message-content-block .message-detail .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    color: #651FFF;
    margin-right: 8px;
}
.message-block.builder-message .message-content-block .message-detail .name {
    color: #304FFE;
}
.message-block .message-content-block .message-detail .time {    
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #3C3E49;
}
.message-listing-block .message-datetime-block{    
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: #9B9FB1;
    margin: 0 0 12px;
}
.message-listing-block .mark-as-resolve-block{    
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: #9B9FB1;
    margin: 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.message-listing-block .mark-as-resolve-block img{
    height: 20px;
    width: 20px;
    object-fit: contain;
    object-position: center;
    margin: 0 8px 0 0;
}
.message-block .message-content-block .ticket-attachment-block {
margin: 4px 0 0;
}
.message-block .message-content-block .ticket-attachment-block .attachment-title-block{    
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    color: #676B7E;
    margin: 0 0 4px;
}
.new-message-notification{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
}
.new-message-notification p{
    padding: 4px 12px;
    color: #fff;
    background-color: #7C4DFF;    
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    border-radius: 16px;
}

.view-image-modal .title-block{
    position: relative;
    z-index: 1;
}
.view-image-modal  .close-btn{
    z-index: 2;
}
.view-image-modal .modal-body{
    padding: 24px;
}
.view-image-modal .image-preview-block:after{
    content: '';
    background: linear-gradient(180.31deg, #FFFFFF 53.03%, rgba(255, 255, 255, 0) 96.49%);
    height: 138px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
}
.view-image-modal .image-preview-block{
    text-align: center;
    margin-top: -10px;
}

.view-image-modal .image-preview-block img{
    width: 100%;
    max-width: 1094px;
    margin: 0 auto;
}
.view-image-modal .image-title-block{
    display: flex;
    align-items: center;    
}
.view-image-modal .image-title-block .profle-image-block .MuiAvatar-root {
    height: 54px;
    width: 54px;
    background-color: #7C4DFF;
    margin-right: 15px;
    border-radius: 38px;
    color: #ffffff;
}
.view-image-modal .image-title-block  .profile-name-block .name-time-block{
    display: flex;
    align-items: center;    
}
.view-image-modal .image-title-block  .profile-name-block .name-time-block .name{    
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    color: #3C3E49;
}

.view-image-modal .image-title-block  .profile-name-block .name-time-block .time{    
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;    
    color: #3C3E49;
    margin-left: 8px;
}
.view-image-modal .image-title-block  .profile-name-block .image-name{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: #3C3E49;
}